import Footer from "../components/App/Footer";
import NavBar from "../components/App/NavBar";
import { ContactForm } from "../components/Contact/ContactForm";
import { SocialContact } from "../components/Contact/SocialContact";

export default function Contacto() {
  return (
    <>
      <NavBar />
      <div className="bg-imageContact lg:mt-24 mt-14 flex items-center justify-center ">
        <h2 className="lg:text-[38px] text-white font-bold">
        Contacto
        </h2>
      </div>

      <div className=" md:py-24 py-14 xl:px-32 lg:px-20 md:px-16 px-8 flex lg:flex-row lg:justify-around flex-col">
        <SocialContact textContact="Platícame como puedo ayudarte enviándome un mensaje o a través de mis redes sociales." />
        <ContactForm />
      </div>
      <Footer />
    </>
  );
}
