import { opcionesTerapeuticas } from "../../../../utils/Data";
import Footer from "../../../App/Footer";
import Navbar from "../../../App/NavBar";
import { NavigationGray } from "../../../App/NavigationRutes";
import { ContactForm } from "../../../Contact/ContactForm";
import { SocialContact } from "../../../Contact/SocialContact";

export const OpcionesTerap = () => {
  return (
    <>
      <Navbar />
      <div className=" lg:mt-24 mt-14 min-h-[248px] flex items-center justify-center relative ">
        <NavigationGray
          path="/psicoterapia"
          title="Psicoterapia"
          subtitle="Opciones terapeúticas"
        />
        <h2 className="lg:text-[38px] font-bold">Opciones terapeúticas</h2>
      </div>

      <div className="bg-imageOpTerapeuticas" />
      <Opciones />
      <ContactCeremonias />
      <Footer />
    </>
  );
};

const Opciones = () => {
  return (
    <div className="flex justify-center items-cente w-full md:py-20 md:px-12 px-9 py-11">
      <div className=" container md:text-left text-center space-y-11">
        {opcionesTerapeuticas.map((item, index) => (
          <div
            className="md:flex md:space-x-6 space-y-6 md:space-y-0"
            key={index}
          >
            <div className="flex md:justify-start items-center justify-center space-x-2 md:w-[35%]">
              <span className="text-txtPurple font-bold italic font-poppins md:text-[36px] text-[24px]">
                {item.number}
              </span>
              <h3 className="italic font-bold font-garamond"> {item.title}</h3>
            </div>

            <div className="md:w-[70%]">
              <p> {item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const ContactCeremonias = () => {
  return (
    <div className=" md:py-24 py-11 xl:px-32 lg:px-20 md:px-16 px-8 flex lg:flex-row lg:justify-around flex-col lg:space-x-10">
      <SocialContact textContact="Envía un correo y toma conciencia de tu energía para una mayor y mejor fuerza de vida." />
      <ContactForm />
    </div>
  );
};
