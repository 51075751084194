import Footer from "../../App/Footer";
import Navbar from "../../App/NavBar";
import { NavigationWhite } from "../../App/NavigationRutes";

export default function Parejas() {
  return (
    <>
      <Navbar />
      <div className="bg-imageParejas lg:mt-24 mt-14 flex items-center justify-center relative ">
        <NavigationWhite path="/especialidades-productos" title="Especialidades y productos" subtitle="Parejas creativas"/>
        <h2 className="lg:text-[38px] text-white font-bold">
        Parejas creativas
        </h2>
      </div>
      <Frase />
      <Posibilidades />
      <Contenido />
      <Footer />
    </>
  );
}

const Frase = () => {
  return (
    <div className="flex justify-center items-center relative bg-bgLila w-full md:py-52 md:px-12 px-9 py-14">
      <div className=" max-w-4xl text-center md:leading-10">
        <p className="mb-10">
          “La pareja es aquella que tiene el poder de despertarme en aquellas
          zonas en las que me encuentro dormida. La que tiene la virtud de
          ponerme en movimiento hacia delante, mostrándome en que aspectos debo
          crecer. Es aquella que me da la oportunidad para ampliar mi conciencia
          y descubrir una verdad más profunda en mí.”
        </p>
        <h3 className="text-txtPurple text-[16px] mb-1 font-bold">
          Marcela Bracho
        </h3>
        <p className="text-[14px]">Psicoterapeuta</p>
      </div>
      <div className="hidden lg:flex absolute z-10  inset-0 ">
        <img
          src="images/about/corazones.png"
          alt="Corazon"
          className="w-full"
        />
      </div>
    </div>
  );
};

const Posibilidades = () => {
  return (
    <div className="flex justify-center items-cente w-full md:py-32 md:px-12 px-9 py-14">
      <div className=" max-w-5xl md:leading-10 md:text-left text-center">
        <div className="space-y-10">
        <h3 className="text-[24px] font-bold">Posibilidades</h3>
        <p>
          Las posibilidades para el encuentro y la sanación de la pareja
          dependen de que sepan crecer juntos. Por ello propongo talleres y
          cursos vivenciales y creativos con temas a la medida, psicoterapia,
          pláticas y conferencias, mesas de reflexión y discusión sana,
          encuentros semanales con otras parejas, espacios Tántricos de fin de
          semana, cine-conciencia con temas contemporáneos, planes turísticos,
          bibliografía y artículos con temas psico-humanistas.
        </p>
        </div>
      </div>
    </div>
  );
};

const Contenido = () => {
  return (
    <>
      <div className="flex justify-center items-center w-full bg-bgCielo">
        <div className="flex flex-col lg:flex-row items-center justify-around lg:py-20 lg:px-0 px-9 py-14 lg:container lg:space-x-20">
          <div className="lg:w-[60%] mb-10 lg:mb-0 text-center lg:text-left">
            <h3 className="mb-10 font-bold lg:text-[30px]">
              Ceremonias Espirituales
            </h3>
            <p className="mb-10">
              Diseñado para parejas que deciden unir sus vidas en ceremonias
              espirituales o renovar sus votos de manera especial.
            </p>
            <p className="mb-10">
              Lo importante de este tipo de rituales sagrados es dar testimonio
              de su amor y su compromiso de una manera original sin tanto
              protocolo convencional. Hay mucha creatividad desbordada en estas
              celebraciones. La pareja forma un espacio energético-espiritual y
              reciben e integran las bendiciones del universo, o de su
              divinidad, cualquiera que esta sea.
            </p>
            <a
              className="btn-blue lg:mx-0 mx-auto"
              href="/ceremonias-espirituales"
              rel="noreferrer noopener"
            >
              Ver más
            </a>
          </div>
          <div className="lg:w-3/12 ">
            <img
              src="images/especialidades/parejas/parejas_ceremonias.jpg"
              alt="parejas ceremonias"
              className="w-full"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center w-full">
        <div className="flex lg:flex-row flex-col-reverse items-center justify-around lg:py-20 lg:px-0 px-9 py-14 lg:container lg:space-x-20">
        <div className="lg:w-3/12 ">
            <img
              src="images/especialidades/parejas/parejas_sexualidad.jpg"
              alt="parejas ceremonias"
              className="w-full"
            />
          </div>
          <div className="lg:w-[60%] mb-10 lg:mb-0 text-center lg:text-left">
            <h3 className="mb-10 font-bold lg:text-[30px]">
              Sexualidad Sagrada
            </h3>
            <p className="mb-10">
              Este es uno de los talleres más solicitados que facilito para
              parejas. Es una manera de aprender a vivir con alegría, vitalidad
              y propósito.
            </p>
            <p className="mb-10">
              En el taller aprendemos a soltar y compartir nuestras energías
              profundas del cuerpo, mente y espíritu. La intención de esta
              práctica milenaria es hacer crecer la intimidad y abrirse con
              quien amas de cuerpo y alma para que circule el amor, enalteciendo
              el sexo hacia un camino de gozo místico y espiritual. Hay sanación
              y liberación de todos los centros energéticos que conforman
              nuestro organismo.
            </p>
            <a
              className="btn-blue lg:mx-0 mx-auto"
              href="/sexualidad-sagrada"
              rel="noreferrer noopener"
            >
              Ver más
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
