import { LogoCorazon } from "../../assets/Logos";
import { contenido } from "../../utils/Data";

export const ContArticulos = () => {
  return (
    <div className=" md:py-32 py-28 xl:px-36 lg:px-28 md:px-12 px-8">
      <div className="mb-16">
        <h2 className="font-bold text-center">Contenido</h2>
        <p className="text-center font-garamond">
          Blogs, videos y programas de radio o podcats para una vida
          <br />
          más plena
        </p>
      </div>

      <div className="grid xl:grid-cols-3 md:grid-cols-2 xl:gap-16 lg:gap-12 gap-10 justify-center">
        {contenido.map((contenido, index) => {
          return (
            <a
              className="flex flex-col hover:scale-95 transform transition-transform duration-300"
              href={contenido.link}
              target="_blank"
              rel="noreferrer noopener"
              key={index}
            >
              <img
                src={contenido.image}
                alt={contenido.title}
                className="w-[504px] h-[268px] object-cover"
              />

              <div className="space-y-4 mt-5">
                <p className="text-[16px]">{contenido.category}</p>
                <h3 className="font-bold">
                  {contenido.title}
                </h3>
                <div className="flex items-center space-x-4">
                  <LogoCorazon fillColor={contenido.fill} />
                  <p className={`${contenido.color} text-[16px]`}>{contenido.medio}</p>
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};
