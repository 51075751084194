import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import AboutMarche from './pages/AboutMarche';
import Especialidad from './pages/Especialidad';
import Contenido from './pages/Contenido';
import Contacto from './pages/Contacto';
import Error404 from './components/App/Error404';
import Parejas from './components/Especialidad_Productos/layouts/Parejas';
import Psicoterapia from './components/Especialidad_Productos/layouts/Psicoterapia';
import { Sexualidad } from './components/Especialidad_Productos/layouts/Parejas/Sexualidad';
import { Ceremonias } from './components/Especialidad_Productos/layouts/Parejas/Ceremonias';
import { OpcionesTerap } from './components/Especialidad_Productos/layouts/Psicoterapia/OpcionesTerap';

function App() {
  return (
    <>
     <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to="/inicio" />} />
        <Route path="/inicio" element={<Home />} />
        <Route path="/sobre-mache" element={<AboutMarche />} />
        <Route path="/especialidades-productos" element={<Especialidad />} />
        <Route path="/contenido" element={<Contenido />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/parejas-creativas" element={<Parejas />} />
        <Route path="/ceremonias-espirituales" element={<Ceremonias />} />
        <Route path="/sexualidad-sagrada" element={<Sexualidad />} />
        <Route path="/psicoterapia" element={<Psicoterapia />} />
        <Route path="/opciones-terapeuticas" element={<OpcionesTerap />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
