export const VideoFrame = () => {
  return (
    <div className="w-full xl:h-[653px] lg:h-[543px] md:h-[453px] sm:h-[353px] h-[220px]">
      <iframe
        className="w-full h-full"
        src="https://www.youtube.com/embed/9n_MWU2SnAo?si=vFW8KXOOn2QIaLIr"
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
};
