import Footer from "../../../App/Footer";
import Navbar from "../../../App/NavBar";
import { NavigationGray} from "../../../App/NavigationRutes";
import { ContactForm } from "../../../Contact/ContactForm";
import { SocialContact } from "../../../Contact/SocialContact";

export const Ceremonias = () => {
  return (
    <>
      <Navbar />
      <div className=" lg:mt-24 mt-14 min-h-[248px] flex items-center justify-center relative ">
        <NavigationGray path="/parejas-creativas" title="Parejas creativas" subtitle="Ceremonias espirituales" />
        <h2 className="lg:text-[38px] font-bold">Ceremonias espirituales</h2>
      </div>

      <div className="bg-imageCeremonias" />
      <Description />
      <ContactCeremonias />
      <Footer />
    </>
  );
};

const Description = () => {
  return (
    <div className="flex justify-center items-cente w-full md:py-32 md:px-12 px-9 py-11">
      <div className=" max-w-5xl md:leading-10 md:text-left text-center space-y-11">
        <h3 className="md:text=[24px] font-bold">
          En las ceremonias hay un contenido simbólico en todos los detalless
        </h3>
        <p>
          Este tipo de rituales sagrados, diseñados para parejas que deciden
          unir sus vidas en ceremonias espirituales o renovar sus votos de
          manera especial, es dar testimonio de su amor y su compromiso de una
          manera original sin tanto protocolo convencional. Hay mucha
          creatividad desbordada en estas celebraciones. La pareja forma un
          espacio energético-espiritual y reciben e integran las bendiciones del
          universo, o de su divinidad, cualquiera que esta sea.
        </p>

        <p>
          Tengo el privilegio de guiar y sostener la ceremonia con la confianza
          concedida por los novios para organizarla y purificar su lazo amoroso.
        </p>

        <p>
          Cada ritual es hecho a la medida de la pareja ya que me permiten que
          viva de cerca su relación previamente.
        </p>
      </div>
    </div>
  );
};

const ContactCeremonias = () => {
  return (
    <div className=" md:py-24 py-11 xl:px-32 lg:px-20 md:px-16 px-8 flex lg:flex-row lg:justify-around flex-col lg:space-x-10">
      <SocialContact textContact="¿Deseas una ceremonia espiritual o renovar tus votos de una manera especial? Platícame cómo quieres celebrar con tu pareja." />
      <ContactForm />
    </div>
  );
};
