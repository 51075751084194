export const esp_prod = [
  {
    title: "Parejas creativas",
    text: "En este tiempo de profundos cambios ideológicos en el mundo, la pareja atraviesa una severa crisis y descomposición emocional con dificultades para establecer vínculos amorosos",
    subtext:
      " Para cambiar su destino, he creado un espacio para parejas que están en busca de una transformación interior y desean vivir de manera íntima su relación promoviendo la creatividad y el continuo crecimiento.",
    image: "/images/home/especialidades_pareja.jpg",
    link: "/parejas-creativas",
    linkText: "Agendar una cita",
    style: "flex-row",
  },
  {
    title: "Psicoterapia",
    text: "Como psicoterapeuta, te acompaño en todo tu proceso interno permitiéndote explorar y reflexionar sobre tu propia existencia en un espacio cálido, seguro y confiable. Indicado a todas aquellas personas con dificultad a enfrentarse con problemas existenciales y que estén buscando una mayor expansión de su potencial personal, mejorar su calidad de vida y con ganas de iniciar un camino interior para su crecimiento integral.",
    subtext: "",
    image: "/images/home/especialidades_terapia.jpg",
    link: "/psicoterapia",
    linkText: "Agendar una cita",
    style: "flex-row-reverse",
  },
  {
    title: "Cursos, talleres y conferencias",
    text: "La educación en línea se ha fortalecido en los últimos años, y es por eso que he facilitado para ti cursos en línea con múltiples temas para el crecimiento personal. Mis talleres y conferencias son diversos y son diseñadas de acuerdo a las necesidades de las personas interesadas o instituciones educativas y/o empresariales. Ambos son espacios de crecimiento para todas aquellas personas en busca de transformación constante.",
    subtext: "/cursos-y-talleres",
    image: "/images/home/especialidades_cursos.jpg",
    link: "",
    linkText: "Agendar una cita",
    style: "flex-row",
  },
];
export const esp_libros = [
  {
    title: "Parejas sin fecha de caducidad",
    description:
      "El amor es un salto al vacío que nos colma de gozo y plenitud y nos exige darlo totalmente. En “Parejas Sin Fecha de Caducidad” hallarás quizá algunas respuestas al desafío de vivir a la par con una pareja duradera en busca de una transformación y un encuentro profundo con el otro. La intensión de este libro es mostrar que sólo en el caos y las crisis más severas se pueden desplegar nuestras potencialidades individuales para hacer honor a la intimidad con uno mismo y con el otro; ",
    subtext:
      "Si honramos a nuestra pareja como al gran maestro que es, e inyectamos de erotismo a la relación, habrá lugar para un destino común imprevisible lleno de gracia, misterio y sin fecha de caducidad.",
    image: "/images/home/libro_parejas.png",
    link: "https://www.amazon.com.mx/Parejas-Fecha-Caducidad-Marcela-Bracho-ebook/dp/B0187W9ZVW/ref=sr_1_2?__mk_es_MX=ÅMÅŽÕÑ&crid=1JL79OM49IUOV&keywords=marcela+bracho&qid=1661412344&sprefix=marcela+bracho%2Caps%2C110&sr=8-2",
    style: "flex-row-reverse",
  },
  {
    title: "Jugar la vida",
    description:
      "El amor es un salto al vacío que nos colma de gozo y plenitud y nos exige darlo totalmente. En “Parejas Sin Fecha de Caducidad” hallarás quizá algunas respuestas al desafío de vivir a la par con una pareja duradera en busca de una transformación y un encuentro profundo con el otro. La intensión de este libro es mostrar que sólo en el caos y las crisis más severas se pueden desplegar nuestras potencialidades individuales para hacer honor a la intimidad con uno mismo y con el otro.",
    subtext:
      "Si honramos a nuestra pareja como al gran maestro que es, e inyectamos de erotismo a la relación, habrá lugar para un destino común imprevisible lleno de gracia, misterio y sin fecha de caducidad.",

    image: "/images/home/libro_jugar.png",
    link: "https://www.amazon.com.mx/Jugar-vida-Marcela-Bracho-Fuentes/dp/6074536961/ref=sr_1_1?__mk_es_MX=ÅMÅŽÕÑ&crid=1JL79OM49IUOV&keywords=marcela+bracho&qid=1661412658&sprefix=marcela+bracho%2Caps%2C110&sr=8-1",
    style: "flex-row",
  },
];
