import { useState } from "react";
import { LogoHorizontal } from "../../assets/Logos";
import { Link, useLocation } from "react-router-dom";

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
  }

  return (
    <nav className="shadow-lg shadow-txtPurple/10 z-50 fixed w-full top-0 bg-white font-poppins">
      <div className="lg:flex justify-between items-center lg:px-20 px-9 py-4 sm:py-6">
        <div className="flex items-center justify-between ">
          <div className="w-28 lg:w-40 md:w-36 sm:w-32">
            <Link to="/inicio">
              <LogoHorizontal />
            </Link>
          </div>

          <div>
            <button
              onClick={toggleMenu}
              className="lg:hidden focus:outline-none"
            >
              {isMenuOpen ? (
                <i className="fas fa-xmark text-3xl text-txtPink"></i>
              ) : (
                <i className="fas fa-bars text-3xl text-txtPink "></i>
              )}
            </button>
          </div>
        </div>

        <div className="lg:flex hidden items-center space-x-8 text-sm lg:text-sm xl:text-base font-bold text-txtPurple">
          <NavLink to="/sobre-mache">Sobre Mache</NavLink>
          <NavLink to="/especialidades-productos">Especialidades y productos</NavLink>
          <NavLink to="/contenido">Contenido</NavLink>
          <NavLink to="/contacto">Contacto</NavLink>
        </div>
      </div>

      <div
        className={`${
          isMenuOpen ? "block" : "hidden"
        } lg:hidden w-full flex top-20 left-0 bg-white z-40 lg:py-0 py-8`}
      >
        <div className="text-txtPurple flex-col flex text-center space-y-4 w-full text-sm font-semibold">
          <NavLink to="/sobre-mache">Sobre Mache</NavLink>
          <NavLink to="/especialidades-productos">Especialidades y productos</NavLink>
          <NavLink to="/contenido">Contenido</NavLink>
          <NavLink to="/contacto">Contacto</NavLink>
        </div>
      </div>
    </nav>
  );
}

function NavLink({ to, children }) {
  const location = useLocation();

  return (
    <a
      href={to}
      rel="noopener noreferrer"
      className={`hover:text-txtPink transition-colors duration-300 ${location.pathname === to ? "text-txtPink" : ""}`}
    >
      {children}
    </a>
  );
}
 