
import { Especialidades } from "../Home/Especialidades";
export const EspecialidadAbout = () => {
    return (
        <>
           <Especialidades />
           {/* <div className="sm:mx-auto sm:max-w-4xl pb-12 lg:px-0 px-6 lg:text-left text-center">
           <p>Si llegaste hasta aquí, muchas gracias. Este es solo el comienzo y me emociona mucho poder compartir contigo esta Master Class gratuita sobre _______ para _______. </p>
           <p>Para tener acceso solo ingresa tu nombre y un correo donde podamos enviarte el acceso. </p>
           </div> */}
        </>
    )
}