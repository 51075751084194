import { LogoTipografia } from "../../assets/Logos";

export default function Footer() {
  return (
    <footer className="sm:p-12 p-8 bg-bgGray text-white">
      <div className="container flex flex-col justify-between mx-auto space-y-8 lg:flex-row lg:space-y-0 lg:space-x-10">
        <div className="space-y-14">
          <div className="w-40 h-10">
            <a rel="noopener noreferrer" href="/inicio">
              <LogoTipografia />
            </a>
          </div>
          <div className=" space-x-5 flex">
            <a
              href="https://www.facebook.com/machebracho"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-txtPink hover:scale-90 transform transition-transform duration-300"
            >
              <i className="fab fa-square-facebook text-3xl" />
            </a>
            <a
              href="https://www.instagram.com/machebracho/"
              target="_blank"
              rel="noopener noreferrer"
              className=" hover:text-txtPink hover:scale-90 transform transition-transform duration-300"
            >
              <i className="fab fa-instagram text-3xl" />
            </a>
          </div>
        </div>

        <div className="grid grid-cols-1 text-base lg:gap-20 gap-5 lg:grid-cols-3 md:grid-cols-2">
          <div>
            <h3 className="text-txtPink ">Explora</h3>
            <div className="space-y-3 flex flex-col my-10">
              <a
                rel="noopener noreferrer"
                href="/inicio"
                className="hover:text-txtPink"
              >
                Inicio
              </a>

              <a
                rel="noopener noreferrer"
                href="/sobre-mache"
                className="hover:text-txtPink"
              >
                Sobre Mache
              </a>

              <a
                rel="noopener noreferrer"
                href="/especialidades-productos"
                className="hover:text-txtPink"
              >
                Especialidades y productos
              </a>

              <a
                rel="noopener noreferrer"
                href="/contenido"
                className="hover:text-txtPink"
              >
                Contenido
              </a>

              <a
                rel="noopener noreferrer"
                href="/contacto"
                className="hover:text-txtPink"
              >
                Contacto
              </a>
            </div>
          </div>

          <div>
            <h3 className="text-txtPink mb-10">Especialidades y productos</h3>
            <div className="space-y-3 flex flex-col my-10">
              <a
                rel="noopener noreferrer"
                href="/parejas-creativas"
                className="hover:text-txtPink"
              >
                Parejas creativas
              </a>

              <a
                rel="noopener noreferrer"
                href="/psicoterapia"
                className="hover:text-txtPink"
              >
                Psicoterapia
              </a>

              <a
                rel="noopener noreferrer"
                href="/especialidades-productos"
                className="hover:text-txtPink"
              >
                Cursos, talleres y conferencias
              </a>

              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.amazon.com.mx/Parejas-Fecha-Caducidad-Marcela-Bracho-ebook/dp/B0187W9ZVW/ref=sr_1_2?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=marcela+bracho&sr=8-2"
                className="hover:text-txtPink italic"
              >
                Parejas sin fecha de caducidad
              </a>

              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.amazon.com.mx/Jugar-vida-Marcela-Bracho-Fuentes/dp/6074536961/ref=sr_1_1?__mk_es_MX=%C3%85M%C3%85%C5%BD%C3%95%C3%91&keywords=marcela+bracho&sr=8-1"
                className="hover:text-txtPink italic"
              >
                Jugar la vida
              </a>
            </div>
          </div>

          <div>
            <h3 className="text-txtPink">Contenido</h3>
            <div className="space-y-3 flex flex-col my-10">
              <a
                rel="noopener noreferrer"
                href="/inicio"
                className="hover:text-txtPink"
              >
                Videos
              </a>

              <a
                rel="noopener noreferrer"
                href="/sobre-mache"
                className="hover:text-txtPink"
              >
                Blogs
              </a>

              <a
                rel="noopener noreferrer"
                href="/especialidades-productos"
                className="hover:text-txtPink"
              >
                Podcast
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end items-center lg:mt-20">
        <p className="text-[16px]">
          <a href="#/" className="text-txtPink">
            Política de privacidad
          </a>{" "}
          |{" "}
          <a href="#/" className="text-txtPink">
            Términos y condiciones
          </a>
        </p>
      </div>
    </footer>
  );
}
