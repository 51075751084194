import { libros } from "../../utils/Data";

export const Libros = () => {
  return (
    <div className=" min-h-screen md:py-24 py-14 xl:px-32 lg:px-20 md:px-16 px-8 bg-bgCielo">
      <div className="lg:space-y-32 space-y-12">
        {libros.map((libro, index) => (
          <div className={`lg:flex ${libro.style} lg:justify-around items-center inline-block`} key={index}>
            <div className="lg:w-1/2 lg:text-left text-center flex flex-col items-center lg:items-start mx-auto">
              <h2 className="font-bold mb-10">{libro.title}</h2>
              <p className="">{libro.description}</p>
              <p className="my-8">{libro.subtext}</p>
              <a
                href={libro.link}
                target="_blank"
                rel="noreferrer noopener"
                className="btn-blue"
              >
                Comprar libro
              </a>
            </div>
            <img className="w-[270px] mx-auto object-cover pt-10 lg:pt-0" src={libro.image} alt={libro.title} />
          </div>
        ))}
      </div>
    </div>
  );
};
