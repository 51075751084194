import { useForm } from "react-hook-form";
import { ErrorAlert, SuccessAlert } from "../../utils/Alerts";

export const ContactForm = () => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onChange" });

  const sendData = async (data) => {
    console.log(data);
    try {
      const response = await fetch("http://localhost:4000/contact-email", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: data.name,
          email: data.email,
          subject: data.subject,
          message: data.message,
        }),
      });

      if (response.status === 200) {
        SuccessAlert("¡Gracias por contactarnos!");
        reset();
      } else {
        ErrorAlert(
          "¡Hubo un problema al contactarnos!. Por favor, inténtalo de nuevo."
        );
      }
    } catch (error) {
      ErrorAlert("Algo salió mal, intenta más tarde");
    }
  };

  return (
    <>
      <div className="bg-txtPink/60 md:p-8 p-4 rounded-lg relative">
        <form
          className="flex flex-col space-y-6 z-10 font-poppins "
          onSubmit={handleSubmit(sendData)}
        >
          <h2 className="font-poppins text-white font-semibold md:pb-10">
            ¡Envíame un mensaje!
          </h2>

          <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0">
            <div className="flex flex-col w-full">
              {errors.name && (
                <span className="required text-sm font-semibold text-red-500 pb-2">
                  * Ingrese su nombre
                </span>
              )}
              <input
                {...register("name", { required: true })}
                id="name"
                type="text"
                placeholder="Nombre"
                className="text-[14px] rounded-lg md:h-12 h-9 px-4 focus:outline-none focus:ring focus:ring-txtPink transition-all duration-200 ease-in-out"
              />
            </div>
            <div className="flex flex-col w-full">
              {errors.email && (
                <span className="required text-sm font-semibold text-red-500 pb-2">
                  {errors.email.type === "required"
                    ? "* Ingrese su correo electrónico"
                    : errors.email.message}
                </span>
              )}
              <input
                {...register("email", {
                  required: true,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]/i,
                    message: "* Correo no válido",
                  },
                })}
                id="email"
                type="email"
                placeholder="Correo electrónico"
                className="text-[14px] rounded-lg md:h-12 h-9 px-4 focus:outline-none focus:ring focus:ring-txtPink transition-all duration-200 ease-in-out"
              />
            </div>
          </div>

          <div className="flex flex-col">
            <select
              {...register("subject", { required: true })}
              className="custom-select text-[14px] rounded-lg md:h-12 h-9 px-4 focus:outline-none focus:ring focus:ring-txtPink transition-all duration-200 ease-in-out"
            >
              <optgroup label="Asunto (Selecciona una opción)">
                <option value="Ceremonia espiritual">
                  Ceremonia espiritual
                </option>
                <option value="Taller - Sexualidad sagrada">
                  Taller - Sexualidad sagrada
                </option>
                <option value="Terapia - Parejas">Terapia - Parejas</option>
                <option value="Terapia - niños">Terapia - niños</option>
                <option value="Terapia - individual adolescente">
                  Terapia - individual adolescente
                </option>
                <option value="Terapia - familia">Terapia - familia</option>
                <option value="Terapia - grupal">Terapia - grupal</option>
                <option value="Terapia - creativa">Terapia - creativa</option>
                <option value="Otro"> Otro </option>
              </optgroup>
            </select>
          </div>

          <div className="flex flex-col">
            {errors.message && (
              <span className="required text-sm font-semibold text-red-500 pb-2">
                * Ingrese su mensaje
              </span>
            )}
            <textarea
              {...register("message", { required: true })}
              id="message"
              className="rounded-lg h-60 px-4 py-2 text-[14px] focus:outline-none focus:ring focus:ring-txtPink transition-all duration-200 ease-in-out"
              placeholder="Mensaje ..."
            />
          </div>
          <button
            type="submit"
            className={`bg-[#7F00B2] text-white h-8 rounded-xl w-1/3 ${
              isValid ? "cursor-pointer" : "opacity-50 cursor-not-allowed"
            }`}
            disabled={!isValid}
          >
            {isValid ? "Enviar" : "Contactar"}
          </button>
        </form>
        <div className="absolute inset-0 -z-10">
          <img
            src="images/app/corazon_textura.png"
            alt="Logo corazon"
            className="w-full"
          />
        </div>
      </div>
    </>
  );
};
