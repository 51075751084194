export const HeroAbout = () => {
  return (
    <>
      <div className="lg:mt-24 mt-14 lg:flex items-center">
        <div className="lg:w-[52%]">
          <img
            src="/images/about/sobremarcela.jpg"
            className="w-full object-cover"
            alt="Marcela"
          ></img>
        </div>

        <div className="lg:w-[48%] lg:px-20 lg:p-0 p-7">
          <h1 className="font-normal lg:text-6xl text-2xl text-txtGray text-center">¡Hola!</h1>
          <h1 className="font-bold lg:text-6xl text-2xl text-txtGray lg:mt-10 mt-5 font-lato text-center">
            Soy Mache
          </h1>
          <div className="lg:text-left text-center lg:mt-14 mt-7 space-y-5 ">
            <p>
              Soy una mujer que ha explorado la danza, la poesía, la escultura,
              el canto, las letras, los tambores y el barro, que ama el arte, la
              cultura y la vida… Buscadora incansable del saber y coleccionista
              de experiencias apasionantes.
            </p>
            <p>
              Tengo 3 hijos maravillosos y un esposo amoroso que me han dado la
              oportunidad de seguir mis sueños.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
