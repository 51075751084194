import { Link } from "react-router-dom";
import { IconPink, IconPurple } from "../../../assets/Icons";
import Footer from "../../App/Footer";
import Navbar from "../../App/NavBar";
import { NavigationWhite } from "../../App/NavigationRutes";

export default function Psicoterapia() {
  return (
    <>
      <Navbar />
      <div className="bg-imagePsicoterapia lg:mt-24 mt-14 flex items-center justify-center relative ">
        <NavigationWhite
          path="/especialidades-productos"
          title="Especialidades y productos"
          subtitle="Psicoterapia"
        />
        <h2 className="lg:text-[38px] text-white font-bold">Psicoterapia</h2>
      </div>
      <Frase />
      <QuePsicoterapia />
      <OpTerapeuticas />
      <Footer />
    </>
  );
}

const Frase = () => {
  return (
    <div className="flex justify-center items-center relative bg-bgLila w-full md:py-52 md:px-12 px-9 py-14">
      <div className=" max-w-4xl text-center md:leading-10">
        <p className="mb-10">
          “La forma como hablamos de lo que sufrimos, cambia lo que sufrimos.”
        </p>
        <h3 className="text-txtPurple text-[16px] mb-1 font-bold">
          Christian Dunker
        </h3>
        <p className="text-[14px]">Psicoanalista</p>
      </div>
      <div className="hidden lg:flex absolute z-10  inset-0 ">
        <img
          src="images/about/corazones.png"
          alt="Corazon"
          className="w-full"
        />
      </div>
    </div>
  );
};

const QuePsicoterapia = () => {
  return (
    <div className="flex justify-center items-cente w-full md:py-32 md:px-12 px-9 py-14">
      <div className=" max-w-5xl md:leading-10 md:text-left text-center space-y-16">
        <div className="md:space-y-10 space-y-8">
          <h3 className="font-bold">Qué es y para qué sirve la psicoterapia</h3>
          <p>
            Es un encuentro profundo e íntimo de seres humanos con sus bondades
            y limitaciones. Por un lado, el paciente como experto de su propia
            vida y como tal, tiene la valentía de buscar un cambio profundo en
            ella, y el segundo experto, es el psicoterapeuta que es capacitado
            en el dominio de técnicas y métodos para generar la transformación
            del paciente.
          </p>
          <p>
            Como psicoterapeuta te acompaño en todo tu proceso interno
            permitiéndote explorar y reflexionar sobre tu propia existencia en
            un espacio cálido, seguro y confiable. En cada sesión, encontrarás
            nuevas posibilidades y recursos internos para que descubras quien
            eres en verdad y no, quien crees que eres, en un viaje a los
            confines más recónditos de tu ser.
          </p>
        </div>

        <div className="space-y-10">
          <h3 className="font-bold">A quién está dirigida</h3>
          <p>
            A todas aquellas personas con dificultad a enfrentarse con problemas
            existenciales tales como conflictos, rupturas, problemas sexuales,
            soledad, duelo, depresión, fobias, ansiedad, dependencias, pérdidas,
            actitudes agresivas, etc. También está indicada para personas que
            estén buscando una mayor expansión de su potencial personal, es
            decir, que deseen mejorar su calidad de vida y con ganas de iniciar
            un camino interior para su crecimiento integral.
          </p>
          <p>
            Las personas aprenden a cuidarse a sí mismas, modificando patrones
            de conducta inservibles y creencias obsoletas e inadecuadas para
            encontrar recursos interiores que los fortalezcan y los nutran.
          </p>
        </div>
      </div>
    </div>
  );
};

const OpTerapeuticas = () => {
  return (
    <>
      <div className="flex justify-center items-center w-full bg-[#ECCDF5]">
        <div className="flex flex-col-reverse lg:flex-row items-center justify-around lg:py-20 lg:px-0 px-9 py-14 lg:container lg:space-x-20">
          <div>
            <img
              src="images/especialidades/psicoterapia/opciones_terapia.jpg"
              alt="parejas ceremonias"
              className="w-full"
            />
          </div>

          <div className="lg:w-[60%] mb-10 lg:mb-0 text-center lg:text-left">
            <h3 className="mb-10 font-bold lg:text-[30px]">
              Opciones Terapeúticas
            </h3>
            <p className="mb-10">
              Estas son las distintas opciones terapeúticas, dedicado para
              grupos o de manera individual de acuerdo a sus necesidades para
              una vida más digna:
            </p>
            <div className=" grid grid-cols-2 gap-5">
              <div className="space-y-6">
                <div className="flex items-center space-x-2">
                  <IconPink />
                  <span className="font-bold md:text-[20px] text-[14px] text-txtPink">Terapia para parejas</span>
                </div>
                <div className="flex items-center space-x-2">
                  <IconPink />
                  <p className="font-bold md:text-[20px] text-[14px] text-txtPink">Terapia de juego para niños</p>
                </div>
                <div className="flex items-center space-x-2">
                  <IconPink />
                  <p className="font-bold md:text-[20px] text-[14px] text-txtPink">Terapia individual adolescente</p>
                </div>
              </div>

              <div className="space-y-6">
                <div className="flex items-center space-x-2 ">
                  <IconPurple />
                  <p className="font-bold md:text-[20px] text-[14px] text-txtPink">Terapia para familia</p>
                </div>
                <div className="flex items-center space-x-2">
                  <IconPurple />
                  <p className="font-bold md:text-[20px] text-[14px] text-txtPink">Terapia para grupos</p>
                </div>
                <div className="flex items-center space-x-2">
                  <IconPurple />
                  <p className="font-bold md:text-[20px] text-[14px] text-txtPink">Terapias creativas</p>
                </div>
              </div>
            </div>

            <Link
              className="btn-blue lg:mx-0 mx-auto mt-10"
              to="/opciones-terapeuticas"
              rel="noreferrer noopener"
            >
              Ver más
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
