export const SocialContact = ({ textContact }) => {
  return (
    <div className="lg:max-w-lg">
      <p className="text-txtPurple md:text-[16px] text-[14px]">
        Detalles de contacto
      </p>
      <h2 className="md:text-[38px] text-[26px] font-bold">Contacto</h2>
      <p className=" md:text-[16px] text-[14px]">{textContact}</p>

      <h2 className="md:text-[26px] text-[18px] md:mt-20 mt-12 mb-6 font-bold md:ml-16">
        Redes
      </h2>
      <div className="flex md:flex-col flex-row space-x-5 md:space-x-0 md:space-y-7 space-y-0">
        <a
          href="https://m.facebook.com/machebracho/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-[16px] hover:text-txtPink flex items-center"
        >
          <i className="fab fa-square-facebook md:text-5xl text-4xl text-txtPurple hover:text-txtPink" />
          <span className="ml-5 md:block hidden hover:text-txtPink">
            @machebracho
          </span>
        </a>
        <a
          href="https://www.instagram.com/machebracho/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-[16px] hover:text-txtPink flex items-center"
        >
          <i className="fab fa-square-instagram md:text-5xl text-4xl text-txtPurple hover:text-txtPink" />
          <span className="ml-5 md:block hidden hover:text-txtPink">
            @machebracho
          </span>
        </a>
        {/* <a
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="flex text-[16px] items-center"
          >
            <i className="fab fa-twitter md:text-5xl text-4xl text-txtPurple hover:text-txtPink" />
            <span className="ml-5 md:block hidden hover:text-txtPink">
              marcelabracho61@gmail.com
            </span>
          </a> */}
      </div>

      <div className="text-[16px] flex items-center md:mt-20 mt-12 mb-6">
        <i className="far fa-envelope bg-bgLila p-2 text-4xl text-txtPurple"></i>
        <a
          href="mailto:marcelabracho61@gmail.com"
          target="_blank"
          rel="noreferrer noopener"
          className="flex flex-col ml-3"
        >
          <h2 className="md:text-[26px] text-[18px] font-bold hover:text-txtPurple">
            Correo
          </h2>
          <span className="hover:text-txtPink">marcelabracho61@gmail.com</span>
        </a>
      </div>
    </div>
  );
};
