import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { testimonios } from "../../utils/Data";

export const Testimonials = () => {
  return (
    <>
      <div className="mx-8 my-10 lg:mx-20">
        <Swiper
          modules={[Pagination, Autoplay]}
          grabCursor={true}
          spaceBetween={80}
          pagination={{dynamicBullets : true}}
          // loop={true}
          // autoplay={{
          //   delay: 2500,
          // }}
          breakpoints={{
            640: {
              slidesPerView: 1,
              pagination: {
                clickable:true
              }
             
            },
            768: {
              slidesPerView: 2,
              pagination: {
                clickable:true
              }
              
            },
            1024: {
              slidesPerView: 2,
              pagination: false,
            },
          }}
        >
          {testimonios.map((testimonio, index) => (
            <SwiperSlide key={index} className="">
              <div className="md:flex inline-block text-lg ">
                <div className="mb-4 md:mb-0">
                  <svg
                    width="62"
                    height="14"
                    viewBox="0 0 62 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.666 13.916H0.674L6.506 0.307999H11.744L8.666 13.916ZM19.574 13.916H11.582L17.414 0.307999H22.652L19.574 13.916ZM42.8396 0.307999H50.7776L44.9456 13.916H39.7616L42.8396 0.307999ZM53.7476 0.307999H61.6856L55.8536 13.916H50.6696L53.7476 0.307999Z"
                      fill="#611AC5"
                    />
                  </svg>
                </div>

                <div className="ml-0 md:ml-8 space-y-7 mb-12 lg:mb-0">
                  <p>{testimonio.descripcion}</p>
                  <p className="text-gray-600 font font-semibold">
                    - {testimonio.nombre}
                  </p>
                </div>

              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="bottom-line lg:flex hidden " />
    </>
  );
};
