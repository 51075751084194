import { esp_prod } from "../../utils/DataEspecialidad";

export const EspecialidadesProd = () => {
  return (
    <>
      <div className="bg-imageEsp lg:mt-24 mt-14 flex items-center justify-center ">
        <h2 className="lg:text-[38px] text-white font-bold">Especialidades y productos</h2>
      </div>
      <div className=" md:py-24 py-14 xl:px-32 lg:px-20 md:px-16 px-8">
        <div className="lg:space-y-14 space-y-12">
          {esp_prod.map((especialidad, index) => (
            <div
              className={`lg:flex ${especialidad.style} lg:justify-around items-center inline-block`}
              key={index}
            >
              <div className="lg:w-1/2 lg:text-left text-center flex flex-col items-center lg:items-start mx-auto">
                <h2 className="font-bold mb-10">{especialidad.title}</h2>
                <p className="">{especialidad.text}</p>
                <p className="my-8">{especialidad.subtext}</p>
                <a
                  href={especialidad.link}
                  rel="noreferrer noopener"
                  className="btn-blue"
                >
                  ver más
                </a>
              </div>
              <img
                className="w-[415px] mx-auto object-cover pt-10 lg:pt-0"
                src={especialidad.image}
                alt={especialidad.title}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
