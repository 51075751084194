import Swal from "sweetalert2";

export const SuccessAlert = (message) => {
  Swal.fire({
    title: "¡Enviado!",
    text: message,
    icon: "success",
    showConfirmButton: false,
    timer: 2500,
  });
};

export const ErrorAlert = (message) => {
  Swal.fire({
    icon: "error",
    title: "¡Error!",
    text: message,
    showConfirmButton: false,
    timer: 2500,
  });
};
