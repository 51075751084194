export const AboutHome = () => {
  return (
    <div className="flex justify-center items-center relative bg-bgLila w-full mt-0 lg:mt-28 md:py-36 px-6 py-14">
      <div className="flex justify-center items-center w-full">
        <div className="lg:flex justify-center items-center lg:space-x-16 inline-block space-x-0 md:leading-10">
          <img
            src="images/home/about.jpg"
            alt="Marche"
            className="w-[420px] "
          />

          <div className="text-center lg:text-left ">
            <h2 className="font-bold my-8 lg:mt-0 ">Conoce a Mache</h2>
            <p>
              Mi formación profesional ha sido multidisciplinaria.
              <br /> Hoy me dedico a la consulta privada como Psicoterapeuta
              Gestalt
              <br />
              Humanista, con varias especialidades, entre ellas: Terapia de
              Juego para
              <br /> Niños, Adolescentes, Terapia de Grupos, Terapia de Parejas,
              Sensibilización
              <br /> Gestalt, Sexualidad y Adicciones.
              <br /> Me he capacitado también en otros diplomados y
              certificaciones tales
              <br /> como: Hipnosis Aplicada, Neurolingüística, Tanatología,
              Psicología
              <br /> Transpersonal y Arte Terapia.
            </p>
            <div className="flex justify-center items-center lg:justify-start w-full">
              <a href="/sobre-mache" rel="noopener noreferrer" className="btn-blue mt-8">
                Leer más sobre Mache
              </a>
            </div>
          </div>
        </div>

        <div className="hidden lg:flex absolute z-10 inset-y-0 right-0">
          <img src="images/home/corazon.png" alt="Corazon" className="w-full" />
        </div>
      </div>
    </div>
  );
};
