import NavBar from "../components/App/NavBar";
import Footer from "../components/App/Footer";
import { EspecialidadesProd } from "../components/Especialidad_Productos/EspecialidadesProd";
import { EspecialidadLib } from "../components/Especialidad_Productos/EspecialidadLib";

export default function Especialidad() {
  return (
    <>
      <NavBar />
      <EspecialidadesProd />
      <EspecialidadLib />
      <Footer />
    </>
  );
}
