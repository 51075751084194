export const testimonios = [
  {
    nombre: "P. Fuentes",
    descripcion:
      "Mache es la persona perfecta para conectar con tu esencia y cumplir tus sueños. Lo que más me ha fascinado de ella y de sus sesiones terapéuticas es su generosidad a la hora de compartir conocimientos y herramientas, su tiempo y su cariño, con gran implicación y profesionalidad, cercanía y pasión.",
  },
  {
    nombre: "Jime y Juan Álvaro",
    descripcion:
      "Gracias Marce por ayudarnos a estar donde estamos y por todo tu cariño y paciencia en el proceso. Sin ti, no hubiéramos llegado al matrimonio. Eres muy especial para nosotros. ",
  },
  {
    nombre: "P. Berumen",
    descripcion:
      "Desde el primer momento conecté con su autenticidad y sencillez y sentí que era la persona ideal para acompañarme en mi autodescubrimiento. ",
  },
];

export const especialidad = [
  {
    title: "Parejas creativas",
    description:
      "Diseñado para parejas que deciden unir sus vidas en ceremonias espirituales o renovar sus votos de manera especial. ",
    image: "/images/home/especialidades_pareja.jpg",
    link: "/parejas-creativas",
    linkText: "Descubre más",
    linkClass: "btn-lineBlue",
  },
  {
    title: "Psicoterapia",
    description:
      "Te acompaño en todo tu proceso interno permitiéndote explorar y reflexionar sobre tu propia existencia en un espacio cálido, seguro y confiable.",
    image: "/images/home/especialidades_terapia.jpg",
    link: "/psicoterapia",
    linkText: "Agendar una cita",
    linkClass: "btn-blue",
  },
  {
    title: "Cursos, talleres y conferencias",
    description:
      "Ambos son espacios de crecimiento para todas aquellas personas en busca de transformación constante. Hay múltiples temas con los cuales trabajar y se estructuran en base a objetivos concretos.",
    image: "/images/home/especialidades_cursos.jpg",
    link: "/cursos-y-talleres",
    // linkText: "Explorar",
    // linkClass: "btn-lineBlue",
  },
];

export const libros = [
  {
    title: "Parejas sin fecha de caducidad",
    description:
      "El amor es un salto al vacío que nos colma de gozo y plenitud y nos exige darlo totalmente. En “Parejas Sin Fecha de Caducidad” hallarás quizá algunas respuestas al desafío de vivir a la par con una pareja duradera en busca de una transformación y un encuentro profundo con el otro. La intensión de este libro es mostrar que sólo en el caos y las crisis más severas se pueden desplegar nuestras potencialidades individuales para hacer honor a la intimidad con uno mismo y con el otro; ",
    subtext:
      "Si honramos a nuestra pareja como al gran maestro que es, e inyectamos de erotismo a la relación, habrá lugar para un destino común imprevisible lleno de gracia, misterio y sin fecha de caducidad.",
    image: "/images/home/libro_parejas.png",
    link: "https://www.amazon.com.mx/Parejas-Fecha-Caducidad-Marcela-Bracho-ebook/dp/B0187W9ZVW/ref=sr_1_2?__mk_es_MX=ÅMÅŽÕÑ&crid=1JL79OM49IUOV&keywords=marcela+bracho&qid=1661412344&sprefix=marcela+bracho%2Caps%2C110&sr=8-2",
    style: "flex-row",
    style1: "flex-row-reverse",
    bg: "bg-bgwhite",
    bg1: "bg-bgLila",
  },
  {
    title: "Jugar la vida",
    description:
      "Una mujer sufre la muerte de su padre. Ella, consciente de que se halla en su segunda juventud, viaja a la India para encontrarse con su hija. Juntas inician una travesía interior que transformará su duelo en alegría, al rendirle un profundo homenaje al padre-abuelo a partir de recordar anécdotas en cada paso que dan.",
    subtext: "A través de estas páginas, el misticismo milenario de la India baila y se entrevera sin censura con la mexicanidad alegre y la orfandad dolida de la protagonista.",
    image: "/images/home/libro_jugar.png",
    link: "https://www.amazon.com.mx/Jugar-vida-Marcela-Bracho-Fuentes/dp/6074536961/ref=sr_1_1?__mk_es_MX=ÅMÅŽÕÑ&crid=1JL79OM49IUOV&keywords=marcela+bracho&qid=1661412658&sprefix=marcela+bracho%2Caps%2C110&sr=8-1",
    style: "flex-row-reverse",
    style1: "flex-row",
    bg: "bg-bgwhite",
    bg1: "bg-bgLila",
  },
];

export const contenido = [
  {
    title: "Recupera el deseo en las relaciones",
    image: "/images/home/contenido_imagen01.jpg",
    link: "https://psicopedia.org/6729/recuperar-el-deseo-en-las-relaciones/",
    category: "Parejas",
    medio: "Artículo",
    fill: "#611AC5",
    color: "text-txtPurple",
  },
  {
    title: "Cómo terminar una relación narcicista",
    image: "/images/home/contenido_imagen02.jpg",
    link: "https://www.facebook.com/siempreentuvidaradio/videos/3176209476029043/?extid=NS-UNK-UNK-UNK-IOS_GK0T-GK1C&ref=sharing",
    category: "Crecimiento personal",
    medio: "Artículo",
    fill: "#611AC5",
    color: "text-txtPurple",
  },
  {
    title: "Separación consciente",
    image: "/images/home/contenido_imagen03.jpg",
    link: "https://www.facebook.com/siempreentuvidaradio/videos/756141645696012/?extid=NS-UNK-UNK-UNK-IOS_GK0T-GK1C&ref=sharing",
    category: "Crecimiento personal",
    medio: "Redes sociales",
    fill: "#C76EEC",
    color: "text-txtPink",
  },
  {
    title: "Infidelidad ¿Cómo superar el dolor?",
    image: "/images/home/contenido_imagen04.jpg",
    link: "https://psicopedia.org/5826/infidelidad-como-superar-el-dolor-que-causa-el-engano/",
    category: "Crecimiento personal",
    medio: "Artículo",
    fill: "#611AC5",
    color: "text-txtPurple",
  },
  {
    title: "Cuando llega la rutina a tu relación de pareja",
    image: "/images/home/contenido_imagen05.jpg",
    link: "https://psicopedia.org/5942/rutina-en-tu-relacion-de-pareja/",
    category: "Parejas",
    medio: "Redes sociales",
    fill: "#C76EEC",
    color: "text-txtPink",
  },
  {
    title: "Sexualidad sagrada en pareja",
    image: "/images/home/contenido_imagen06.jpg",
    link: "https://www.facebook.com/siempreentuvidaradio/videos/247367250793686/?extid=NS-UNK-UNK-UNK-IOS_GK0T-GK1C&ref=sharing",
    category: "Parejas",
    medio: "Redes sociales",
    fill: "#C76EEC",
    color: "text-txtPink",
  },
];

export const opcionesTerapeuticas = [
  {
    number: "1",
    title: "Terapia para parejas",
    description:
      "Más que una consulta terapéutica, es una sala de encuentro. Acompaño a las parejas a que descubran sus partes ciegas, esas zonas oscuras y ocultas que no desean ver, por lo que, buscamos juntos posibles alternativas de encuentro profundo, descubriendo respuestas a la situación conflictiva del momento actual. Se les enseña las herramientas necesarias para poner en una balanza lo bueno y lo malo de la relación, “lo que hacen bien”, sus metas individuales y como pareja, cómo promover un diálogo constructivo y donde sean capaces de expresar sus emociones e ideas respetando al otro.",
  },
  {
    number: "2",
    title: "Terapia de juego para niños",
    description:
      "Mediante un adecuado diagnóstico, utilizo técnicas de terapia de juego y actividades creativas como barro, pintura, cuentos, collage, muñecos, títeres, arenero, etc. Apoyo al niño en sus diferentes etapas de desarrollo y en sus comportamientos inadecuados. El objetivo es ayudarle a maximizar los aprendizajes para dar frente a los acontecimientos que la vida le depare. Se trabajan todo tipo de problemas propios de su edad como son los berrinches, las fobias, comportamientos agresivos o represivos, actitudes compulsivas, problemas de concentración, etc.  ",
  },
  {
    number: "3",
    title: "Terapia individual adolescente",
    description:
      "Trabajo en el surgimiento de su nueva identidad, en el desarraigo, en sus valores, así como, dudas acerca de la sexualidad y sentido de vida. Los conflictos principales con ellos son: Depresión y baja estima, trastornos alimenticios, ansiedad, confusión de identidad, conductas autodestructivas tales como: adicciones tóxicas (alcohol, drogas) y no tóxicas (videojuegos, Internet, pornografía) y tendencias suicidas (ponerse en riesgo-vivir al límite, lastimarse físicamente); delincuencia, aislamiento social, relaciones intrafamiliares disfuncionales, maltrato físico y psicológico y el Bullying. ",
  },
  {
    number: "4",
    title: "Terapia para familia",
    description:
      "Trabajo con familias en forma de círculos mágicos, en donde se expone abiertamente la problemática entre padres e hijos y hermanos. Promoviendo la expresión de sentimientos, se dan herramientas para resolver conflictos, y se sanan asuntos inconclusos, rencores y resentimientos añejados, problemas de autoridad y disciplina, facilitando de esa manera, un acercamiento amoroso entre todos los integrantes.",
  },
  {
    number: "5",
    title: "Terapia para grupos",
    description:
      "El grupo es un lugar rico en el que nos proyectamos y nos relacionamos tal y como somos, en las diferentes situaciones de nuestra vida. Contar con otras personas a nuestro alrededor, en nuestro proceso de desarrollo personal, supone una oportunidad para tomar conciencia de cómo nos relacionamos con el mundo y cómo nos situamos dentro de él. Uno de los objetivos del proceso consiste en desafiar a los integrantes del grupo para que descubran nuevas alternativas y escojan entre ellas.",
  },
  {
    number: "6",
    title: "Terapias creativas",
    description:
      "Es una buena alternativa para niños, adolescentes y adultos que les cueste trabajo expresarse y que da rienda suelta a la creatividad para manifestar pensamientos y sentimientos guardados. Promueven el encuentro con uno mismo y con las emociones, pudiendo acceder a conflictos profundos que son difíciles de llegar a través de la palabra. Entre las terapias creativas se encuentran: la terapia de arte, la danza terapéutica, la musicoterapia, la terapia poética o escritura-terapia, entre otras. Sus aplicaciones están en las áreas de educación y salud mental. Las utilizo con aquellas personas con problemas sensoriales, físicos-motores o de adaptación social como en el caso de Síndrome de Down, autismo o Alzheimer. Niños con problemas de aprendizaje, fobias, depresión, déficit de atención o problemas de hiperactividad.",
  },
];
