import Footer from "../components/App/Footer";
import NavBar from "../components/App/NavBar";
import { AboutHome } from "../components/Home/AboutHome";
import { Contenido } from "../components/Home/Contenido";
import { Especialidades } from "../components/Home/Especialidades";
import { Hero } from "../components/Home/Hero";
import { Libros } from "../components/Home/Libros";
import { Testimonials } from "../components/Home/Testimonials";

export default function Home() {
  return (
    <>
      <NavBar />
      <Hero />
      <Testimonials />
      <AboutHome />
      <Especialidades />
      <Libros />
      <Contenido />
      <Footer />
    </>
  );
}
