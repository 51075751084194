import { contenido } from "../../utils/Data";

export const Contenido = () => {
  return (
    <div className="min-h-screen md:py-24 py-14 xl:px-36 lg:px-28 md:px-12 px-8">
      <div className="mb-16">
        <h2 className="font-bold text-center">Contenido</h2>
        <p className="text-center font-garamond">
          Me encanta compartir historias, pensamientos y formas de ayudar.
          <br />
          Aquí encontrarás contenido que te impulsará a tener una vida
          <br />
          más saludable y amorosa{" "}
        </p>
      </div>

      <div className="grid lg:grid-cols-2 md:grid-cols-2 xl:gap-16 lg:gap-12 gap-10">
        {contenido.map((contenido, index) => {
          return (
            <a
              className="lg:flex inline-block items-center shadow-xl shadow-txtPurple/10 rounded-xl hover:scale-105 transform transition-transform duration-300"
              href={contenido.link}
              target="_blank"
              rel="noreferrer noopener"
              key={index}
            >
              <div className="lg:w-[182px] h-[182px]">
                <img
                  src={contenido.image}
                  alt={contenido.title}
                  className="w-full h-full object-cover rounded-xl"
                />
              </div>

              <div className="space-y-5 p-10 lg:p-0 lg:w-3/4 lg:mx-5  mx-0">
                <p className="font-bold xl:text-[26px] lg:text-[23px] text-[18px] font-garamond hover:text-txtPurple transition duration-300">
                  {contenido.title}
                </p>
                <p className="">{contenido.category}</p>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};
