import React from "react";

export default function Error404() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-bgLila relative">
      <div className="flex flex-col items-center z-10">
        <div className="text-txtPink font-bold text-7xl">404</div>
        <div className="font-bold text-3xl xl:text-7xl lg:text-6xl md:text-5xl mt-10">
          Esta página no existe
        </div>

        <div className="text-gray-400 font-medium text-sm md:text-xl lg:text-2xl mt-8">
          No se pudo encontrar la página que estás buscando.
        </div>
        <div className="flex justify-center mt-10">
          <a
            href="/"
            className="flex justify-center items-center h-12 w-40 uppercase rounded-full font-bold hover:bg-txtPink hover:text-white border-txtPink border-2 bg-transparent text-txtPink transition duration-300 ease-in-out"
          >
            <i className="fas fa-house mr-3"/>Inicio
          </a>
        </div>
      </div>

      <div className="flex absolute md:inset-y-0 right-0">
        <img src="images/home/corazon.png" alt="Corazon" className="w-full" />
      </div>
    </div>
  );
}
