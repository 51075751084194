import Footer from "../components/App/Footer";
import NavBar from "../components/App/NavBar";
import { ContArticulos } from "../components/Contenido/ContArticulos";

export default function Contenido() {
    return (
        <>
          <NavBar />
          <ContArticulos />
          <Footer />
        </>
    )
}