import { esp_libros } from "../../utils/DataEspecialidad";

export const EspecialidadLib = () => {
  return (
    <div className=" min-h-screen md:py-20 py-12 xl:px-32 lg:px-20 md:px-16 px-8">
      <div className="lg:space-y-32 space-y-12">
        {esp_libros.map((libro, index) => (
          <div
            className={`lg:flex ${libro.style} lg:justify-around items-center inline-block`}
            key={index}
          >
            <div className="lg:w-1/2 lg:text-left text-center flex flex-col items-center lg:items-start mx-auto">
              <h2 className="font-bold mb-10 italic">{libro.title}</h2>
              <p className="">{libro.description}</p>
              <p className="my-8">{libro.subtext}</p>
              <a
                href={libro.link}
                target="_blank"
                rel="noreferrer noopener"
                className="btn-blue"
              >
                Comprar libro
              </a>
            </div>
            <div className="lg:w-[415px] w-[320px] mx-auto flex justify-center items-center bg-bgLila mt-10 lg:mt-0">
              <img
                className=" w-[221px] object-cover"
                src={libro.image}
                alt={libro.title}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
