import { Expertise } from "../components/AboutMarche/Expertise";
import { HeroAbout } from "../components/AboutMarche/HeroAbout";
import { VideoFrame } from "../components/AboutMarche/VideoFrame";
import Navbar from "../components/App/NavBar";
import Footer from "../components/App/Footer";
import { EspecialidadAbout } from "../components/AboutMarche/EspecialidadAbout";
// import { MasterClass } from "../components/AboutMarche/MasterClass";

export default function AboutMarche() {
  return (
    <>
      <Navbar />
      <HeroAbout />
      <Expertise />
      <VideoFrame />
      <EspecialidadAbout />
      {/* <MasterClass /> */}
      <Footer />
    </>
  );
}
