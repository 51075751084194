import { especialidad } from "../../utils/Data";

export const Especialidades = () => {
  return (
    <div className="min-h-screen md:py-24 py-14 xl:px-36 lg:px-28 md:px-20 px-8">
      <div className="mb-16">
        <h2 className="font-bold text-center">Especialidades y productos</h2>
        <p className="text-center font-garamond">
          Me encanta compartir historias, pensamientos y formas de ayudar.
          <br />
          Aquí encontrarás contenido que te impulsará a tener una vida más
          <br />
          saludable y amorosa
        </p>
      </div>

      <div className="grid xl:grid-cols-3 lg:grid-cols-2 md:grid-cols-2 xl:gap-16 lg:gap-12 gap-10">
        {especialidad.map((especialidad, index) => {
          return (
            <div
              className="flex flex-col justify-between items-center"
              key={index}
            >
              <img
                src={especialidad.image}
                alt={especialidad.title}
                className="w-[270] h-[246px] object-cover"
              />

              <div>
                <h3 className="font-bold text-center my-10">
                  {especialidad.title}
                </h3>
                <p className="text-center">{especialidad.description}</p>
              </div>

              <div className="mt-10">
                <a
                  href={especialidad.link}
                  rel="noopener noreferrer"
                  className={especialidad.linkClass}
                >
                  {especialidad.linkText}
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
