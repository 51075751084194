import Footer from "../../../App/Footer";
import Navbar from "../../../App/NavBar";
import { NavigationGray } from "../../../App/NavigationRutes";
import { ContactForm } from "../../../Contact/ContactForm";
import { SocialContact } from "../../../Contact/SocialContact";

export const Sexualidad = () => {
  return (
    <>
      <Navbar />
      <div className=" lg:mt-24 mt-14 min-h-[248px] flex items-center justify-center relative ">
        <NavigationGray
          path="/parejas-creativas"
          title="Parejas creativas"
          subtitle="Sexualidad sagrada"
        />
        <h2 className="lg:text-[38px] font-bold">Sexualidad sagrada</h2>
      </div>

      <div className="bg-imageSexualidad" />
      <Description />
      <ContactCeremonias />
      <Footer />
    </>
  );
};

const Description = () => {
  return (
    <div className="flex justify-center items-cente w-full md:py-32 md:px-12 px-9 py-11">
      <div className=" max-w-5xl md:leading-10 md:text-left text-center space-y-11">
        <h3 className="md:text=[24px] font-bold">
          En el taller aprendemos a soltar y compartir nuestras energías
          profundas del cuerpo, mente y espíritu.
        </h3>
        <p>
          Dentro de los cursos y talleres que facilito para las parejas, uno de
          los más solicitados es el taller de <span className="font-bold">Sexualidad Sagrada</span>, en donde se
          enaltece el sexo hacia un camino de gozo místico y espiritual.
        </p>

        <p>
          La intención de esta práctica milenaria es hacer crecer la intimidad y
          abrirse con quien amas de cuerpo y alma para que circule el amor.
        </p>

        <p>
          Es una manera de explorar otros caminos y movernos de ideas
          recalcitrantes y obsoletas para entrar a una completitud total con
          nuestra pareja dejando atrás la vergüenza y la culpa y abrazando la
          belleza y la integridad del corazón. Trabajamos en la sanación de las
          heridas emocionales y el desbloqueo y limpieza de la energía sexual a
          través de <span className="font-bold">ejercicios respiratorios, corporales y de expresión
          emocional en un ambiente de gran cuidado y respeto hacia todos los
          participantes.</span>
        </p>
      </div>
    </div>
  );
};

const ContactCeremonias = () => {
  return (
    <div className=" md:py-24 py-11 xl:px-32 lg:px-20 md:px-16 px-8 flex lg:flex-row lg:justify-around flex-col lg:space-x-10">
      <SocialContact textContact="Envía un correo y toma conciencia de tu energía para una mayor y mejor fuerza de vida." />
      <ContactForm />
    </div>
  );
};
