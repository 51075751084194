export const Hero = () => {
  return (
    <div className="bg-image">
      <div className="flex flex-col items-center justify-center min-h-screen text-white space-y-7 md:space-y-14 px-5 md:p-0">
        <div className="font-bold text-center tracking-wider ">
          <h1>La pareja es la escuela más</h1>
          <h1>importante de la vida</h1>
        </div>

        <div className=" text-center">
          <p className="text-white">
            A través de mis{" "}
            <span className="italic font-bold text-white">
              ceremonias espirituales, terapias, cursos y talleres,
            </span>
          </p>
          <p className="text-white">
            permíteme ayudarte a mejorar la relación con tu pareja
          </p>
          <p className="text-white">o contigo mism@.</p>
        </div>

        <div className="md:flex md:space-x-10 md:space-y-0 space-y-4">
          <a
            href="/especialidades-productos"
            rel="noopener noreferrer"
            className="btn-blue"
          >
            Especialidades y productos
          </a>
          <a href="/sobre-mache" rel="noopener noreferrer" className="btn-line">
            Conoce a Mache
          </a>
        </div>
      </div>
    </div>
  );
};
