export const Expertise = () => {
  return (
    <>
      <div className="flex justify-center items-center relative bg-bgLila w-full  md:py-36 md:px-12 px-9 py-14">
        <div className="lg:flex justify-center items-center lg:space-x-16 inline-block space-x-0 md:leading-10">
          <div className="text-center lg:text-left max-w-5xl md:space-y-14 space-y-7">
            <h2 className="font-bold text-center">Expertise</h2>
            <p>
              Mi formación profesional ha sido multidisciplinaria. Hoy me dedico
              a la consulta privada como <span className="font-bold">Psicoterapeuta Gestalt Humanista</span>, con
              varias especialidades, entre ellas: <span className="font-bold">Terapia de Juego para Niños,
              Adolescentes, Terapia de Grupos, Terapia de Parejas,
              Sensibilización Gestalt, Sexualidad y Adicciones.</span> Me he capacitado
              también en otros diplomados y certificaciones tales como <span className="font-bold">Hipnosis
              Aplicada, Neurolingüística, Tanatología, Psicología Transpersonal
              y Arte Terapia.</span>
            </p>
            <p>
              Fiel a una búsqueda interior me encaminé simultáneamente hacia
              iniciaciones espirituales tales como Tantra (Sexualidad Sagrada),
              Reiki niveles I y II por el sistema Usui Shiki Ryoho, Diksha Giver
              por the Oneness University en India y Kriya Yoga en Texas, EU. He
              practicado Hatha Yoga y Kundalini Yoga por más de 20 años.
            </p>
            <p>
              <span className="font-bold italic">¿Cómo es que decidí dedicarme a la psicoterapia? ¿Cómo es que
              cambié el rumbo de mi vida? </span> En el video de abajo te platico cómo
              encontre mi propósito, como puedo ayudarte a solucionar tus
              problemas y cambiar el rumbo de tu vida para bien.
            </p>
          </div>
        </div>

        <div className="hidden lg:flex absolute z-10  inset-0 ">
          <img
            src="images/about/corazones.png"
            alt="Corazon"
            className="w-full"
          />
        </div>
      </div>
    </>
  );
};
