import { Link } from "react-router-dom"

export const NavigationWhite = ({title,subtitle, path}) => {
    return (
        <div className="font-garamond md:text-[18px] text-[13px] absolute lg:left-52 lg:top-7 sm:top-11 top-5 left-4 text-white">
          <Link to={path} className="hover:text-txtPink">
            {title} 
          </Link>
            <span className="mx-2 text-white">/</span>
          <label className="font-bold">
             {subtitle}
          </label>
        </div>
    )
}

export const NavigationGray = ({title,subtitle, path}) => {
    return (
        <div className="font-garamond md:text-[18px] text-[13px] absolute lg:left-52 lg:top-7 sm:top-11 top-5 left-4">
          <Link to={path} className="hover:text-txtPink">
            {title} 
          </Link>
            <span className="mx-2">/</span>
          <label  className="font-bold">
             {subtitle}
          </label>
        </div>
    )
}